import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const CancelPage = () => (
  <Layout>
    <SEO title="Cancel" />
    <h1>Cancel successful</h1>
    <p></p>
  </Layout>
)

export default CancelPage
